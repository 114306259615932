<template>
  <header id="header">
    <div class="inner">
      <el-row type="flex" justify="center" align="middle">
        <el-col
          :span="6"
          @click.native="$router.push('/home')"
          class="logo-box"
        >
          <svg
            id="logo"
            t="1635153777795"
            class="icon"
            viewBox="0 0 1260 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1858"
            width="36"
            height="36"
          >
            <path
              d="M1259.846921 121.148242c-46.524504 20.728739-96.273478 34.547899-148.325646 40.536201 53.434084-31.784067 94.430924-82.454319 113.777747-142.797982-50.209613 29.480874-105.486251 51.13089-164.447999 62.646857A257.584528 257.584528 0 0 0 872.449815 0.000276c-142.797982 0-258.418284 115.620302-258.418284 258.418284 0 20.268101 2.303193 40.075563 6.909579 58.961748C405.82286 306.32498 215.579097 203.602561 87.98219 47.446058c-22.110655 38.233008-35.008538 82.454319-35.008538 129.900099 0 89.824537 45.603227 168.593747 115.159663 215.118251-42.378756-1.381916-81.99368-12.897882-117.002217-32.244706v3.224471c0 125.293713 88.90326 229.398049 207.287393 253.351259-21.650017 5.988302-44.681949 9.212773-68.17452 9.212773-16.582991 0-32.705344-1.842555-48.827697-4.606387 32.705344 102.722419 128.518184 177.345881 241.374653 179.649074-88.442621 69.095798-199.917175 110.553277-321.06514 110.553277-20.728739 0-41.457479-1.381916-61.72558-3.685109 114.238386 73.241546 250.126788 116.08094 396.149241 116.08094 475.379089 0 735.179289-393.846048 735.179289-735.179289 0-11.055328-0.460639-22.571294-0.921277-33.626621 51.13089-36.851092 94.891562-82.454319 129.439461-134.045848z"
              fill="#55ACEE"
              p-id="1859"
            ></path>
          </svg>
          <p>Twitter</p>
        </el-col>

        <el-col :span="12" class="top-menu">
          <a
            href="https://www.baidu.com/s?wd=el-icon-video-camera"
            class="el-icon-video-camera"
          ></a>

          <a
            href="https://www.baidu.com/s?wd=el-icon-s-data"
            class="el-icon-s-data"
          ></a>

          <a
            href="https://www.baidu.com/s?wd=el-icon-date"
            class="el-icon-date"
          ></a>

          <a
            @click="
              $router.push(
                `/account/${$store.state.userStore.userInfo.user_name}/${$store.state.userStore.userInfo.user_id}`
              )
            "
            href="javascript:;"
            class="el-icon-s-custom"
          ></a>
        </el-col>

        <el-col :span="6" class="settings">
          <!-- <p>
            <i class="el-icon-setting"></i>
          </p>
          <p>
            <i class="el-icon-star-on"></i>
          </p>
          <p>
            <i class="el-icon-edit"></i>
          </p>
          <p>
            <i class="el-icon-shopping-bag-1"></i>
          </p> -->

          <el-input
            class="search"
            prefix-icon="el-icon-search"
            placeholder="Search Twitter"
            v-model="searchValue"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
    </div>
  </header>
</template>

<script>
export default {
  name: "layoutHeader",
  data() {
    return {
      searchValue: "",
    };
  },
};
</script>

<style lang="scss" scoped>
$headerHeight: 57px;

#header {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgb(239, 243, 244);
  //   position: fixed;
  //   top: 0;
  //   left: 0;

  .inner {
    width: 1200px;
    height: $headerHeight;
    line-height: $headerHeight;
    margin: 0 auto;

    .logo-box {
      width: 275px;
      display: flex;
      align-items: center;
      cursor: pointer;

      #logo {
        display: block;
        margin-right: 10px;
      }
    }

    .top-menu {
      width: 650px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 30px;

      & > a {
        color: #333;
        text-decoration: none;

        &.active {
          color: #f09040;
        }
      }
    }

    .settings {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      p {
        width: 35px;
        height: 35px;
        border-radius: 9999px;
        background-color: #e5e6eb;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 18px;
        }
      }
    }

    .search {
      width: 260px;
      margin-left: 30px;
    }
    /deep/ .search .el-input__inner {
      width: 260px;
      border-radius: 9999px;
      padding: 0 40px;
      font-size: 14px;
    }
  }
}
</style>>
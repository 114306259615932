<template>
  <div>
    <LayoutHeader />
    <main id="main">
      <LayoutAside />
      <div id="pages">
        <router-view></router-view>
      </div>
      <LayoutTrends />
    </main>
  </div>
</template>

<script>
import LayoutHeader from "@/layout/Header";
import LayoutAside from "@/layout/Aside";
import LayoutTrends from "@/layout/Trends";

export default {
  components: { LayoutHeader, LayoutAside, LayoutTrends },
};
</script>
<style lang="scss" scoped>
#main {
  //   width: 1133px;
  width: 1200px;
  //   margin: 58px auto 0 auto;
  margin: 0 auto;
  display: flex;

  #pages {
    width: calc(1200px - 276px - 276px);
    height: calc(100vh - 58px);
    border-right: 1px solid rgb(239, 243, 244);
    border-left: 1px solid rgb(239, 243, 244);
    overflow: scroll;
  }
}
</style>
<template>
  <div class="right-aside">
    <ul>
      <li class="frend-title">Trends for you</li>
      <li v-for="{ title, trend, tweets } in trendsList" :key="title">
        <div>
          <p class="trend">{{ trend }}</p>
          <svg viewBox="0 0 24 24" aria-hidden="true">
            <g>
              <circle cx="5" cy="12" r="2"></circle>
              <circle cx="12" cy="12" r="2"></circle>
              <circle cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </div>
        <p class="title">{{ title }}</p>
        <p class="tweets">{{ tweets }}</p>
      </li>
      <li class="more">
        <p>Show more</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "trends",
  data() {
    return {
      trendsList: [
        {
          title: "Apple",
          trend: "Technology · Trending",
          tweets: "392k Tweets",
        },
        {
          title: "React",
          trend: "Technology · Trending",
          tweets: "392k Tweets",
        },
        {
          title: "Angular",
          trend: "Technology · Trending",
          tweets: "392k Tweets",
        },
        { title: "Vue", trend: "Technology · Trending", tweets: "392k Tweets" },
        {
          title: "TypeScript",
          trend: "Technology · Trending",
          tweets: "1392k Tweets",
        },
        {
          title: "JavaScript",
          trend: "Technology · Trending",
          tweets: "2392k Tweets",
        },
        {
          title: "ECMAScript 2018",
          trend: "Technology · Trending",
          tweets: "2392k Tweets",
        },
        {
          title: "ECMAScript 2020",
          trend: "Technology · Trending",
          tweets: "2392k Tweets",
        },
        {
          title: "ECMAScript 2021",
          trend: "Technology · Trending",
          tweets: "2392k Tweets",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.right-aside {
  overflow: hidden;
  width: 260px;
  margin-left: 17px;
  margin-top: 11px;

  ul {
    list-style: none;
    background-color: rgb(247, 249, 249);
    border-radius: 16px;

    li {
      cursor: pointer;
      padding: 12px 16px;
      transition: all 100ms ease-in-out;

      &.more {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        p {
          color: rgb(29, 155, 240);
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      &.frend-title {
        color: rgb(15, 20, 25);
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        cursor: default;
      }

      &:not(.frend-title):hover {
        background-color: #eff1f1;
      }

      & > div {
        display: flex;
        justify-content: space-between;

        svg {
          width: 1.25em;
          height: 16px;
          fill: currentcolor;
        }
      }

      p.trend,
      p.tweets {
        color: rgb(83, 100, 113);
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
      }

      p.title {
        margin-top: 2px;
        margin-bottom: 4px;
        color: rgb(15, 20, 25);
        font-size: 15px;
        line-height: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>